<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="contactInfo">
      <div class="contactIcon telegram" @click="toTg"></div>
      <div class="contactIcon potato" @click="toPotato"></div>
      <!-- <div class="contactIcon twitter"></div> -->
    </div>
    <div class="downloadBox">
      <div class="qrBg">
        <img class="qrcodeBox" :src="qrImg" alt="" />
      </div>
      <div class="tip"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {};
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/+4dWHoUJFdbI0NDRl");
    },
    toPotato() {
      window.open("https://ptd6.app/nutpro");
    },
    jumpOfficialWeb() {
      window.open("https://eros01.com");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .contactInfo {
    width: 130px;
    height: 50px;
    position: fixed;
    top: 48px;
    right: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .contactIcon {
      width: 50px;
      height: 50px;
    }
    .telegram {
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
    }
    .potato {
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
    }
    .twitter {
      background: url("./../../../assets/images/pc/twitter.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 253px;
    bottom: 110px;
    .qrBg {
      height: 185px;
      width: 167px;
      background: url("../../../assets/images/pc/qrBg.png") no-repeat;
      background-size: 100% 100%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // margin-right: 18px;
    }
    .qrcodeBox {
      width: 105px;
      height: 105px;
      background-color: white;
      margin-left: 36px;
      margin-top: 34px;
      // background: red;
      // border-radius: 10px;
    }
    .tip {
      width: 338px;
      height: 185px;
      background: url("../../../assets/images/pc/tip.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
